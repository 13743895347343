
<template>
  <div id="app" class="bible-container">
    <div class="verse" v-if="verse.bookname && verse.chapter && verse.verse">
      <h1>{{ verse.bookname }} {{ verse.chapter }}:{{ verse.verse }}</h1>
      <p>{{ verse.text }}</p>
    </div>
    <div v-else>
      <p>Loading...</p>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'App',
  data() {
    return {
      verse: {
        bookname: 'genesis',
        chapter: '5',
        verse: '25',
        text: 'When Methuselah had lived 187 years, he became the father of Lamech. ',
      }
    };
  },
  mounted() {
    this.fetchVerse();
  },
  methods: {
    fetchVerse() {
      axios.get('https://bibleapi.co/api/verses/niv/random')
        .then(response => {
          // Check the structure of the API response
          const data = response.data;
          // Ensure the data is being mapped correctly
          this.verse = {
            bookname: data.book || 'Unknown Book',
            chapter: data.chapter || 'Unknown Chapter',
            verse: data.verse || 'Unknown Verse',
            text: data.text || 'No text available',
          };
        })
        .catch(error => {
          console.error("There was an error fetching the verse:", error);
        });
    }
  }
};
</script>

<style>
html, body, #app {
  margin: 0;
  padding: 0;
  height: 100%;
}

.bible-container {
  background-image: url('@/assets/bibleb.jpeg');
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  color: white;
  text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.7);
}

.verse {
  background: rgba(0, 0, 0, 0.5);
  padding: 20px;
  border-radius: 10px;
  text-align: center;
}
</style>
